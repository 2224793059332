











































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import AddMembersFromFileModal from './components/AddMembersFromFileModal.vue';
import AddMembersFromListModal from './components/AddMembersFromListModal.vue';
import {
  CreateNewGroupRequestPayload,
  CreateNewGroupResponsePayload,
  ProtectedGroupTypes
} from '@/store/modules/admin/types/group.types';
import { Action, State } from 'vuex-class';
import Container from '@/components/Container.vue';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { GetSignedUrlForUploadResponsePayload } from '@/store/modules/static-file/types/static-file.types';
import AgreementSection from '@/views/AdminCreateGroup/components/AgreementSection.vue';
import GroupCreationSubmitButtons from '@/views/AdminCreateGroup/components/GroupCreationSubmitButtons.vue';
import GroupAttributeForm from '@/views/AdminCreateGroup/components/GroupAttributeForm.vue';
import EmailDomainForm from '@/views/AdminCreateGroup/components/EmailDomainForm.vue';
import ProtectedGroupSection from '@/views/AdminCreateGroup/components/ProtectedGroupSection.vue';
import MembersUploadSection from '@/views/AdminCreateGroup/components/MembersUploadSection.vue';
import ExistingMembersSelector from '@/views/AdminCreateSubGroup/components/ExistingMembersSelector.vue';
import EditableMemberTable from '@/views/AdminCreateGroup/components/EditableMemberTable.vue';
import GroupLevelAttribute from '../AdminGroupSettings/components/GroupLevelAttribute.vue';
import GroupNameAndTypeForm from '../AdminCreateGroup/components/GroupNameAndTypeForm.vue';
import { DialogProgrammatic, ToastProgrammatic } from 'buefy';
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from 'vee-validate';
import { RootState } from '@/store/store';
import {
  AddExistingMemberPayload,
  MemberObject,
  userAttributesArrWithValues,
  validatedGroupUserAttributeValue
} from '@/utils/group.util';
import {
  GetProtectedGroupTypesResponsePayload,
  GroupType
} from '@/store/modules/admin/types/admin.types';
import { UserAttributesStringInputOption } from '@/store/modules/admin/types/group.types';
import {
  GroupTemplateAttribute,
  GroupLevelAttributeValue,
  GroupLevelAttributeValuePayload,
  GroupLevelAttributeListType,
  GroupTemplatePayload,
  GroupTemplateAttributeStatus
} from '@/store/modules/admin/types/group-level-attribute.types';
import {
  GroupUserAttributeTypeStatus,
  MyjbiGroupUserAttribute,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import UserAttributeTab from './components/UserAttributeTab/UserAttributeTab.vue';
import { ResourceExceptions } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

export const defaultAttributes: MyjbiGroupUserAttributeSpec[] = [
  {
    groupUserAttribute: {
      id: 1,
      name: 'Email',
      slug: 'email',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 2,
        type: 'email',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 2,
      name: 'First Name',
      slug: 'first-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 3,
      name: 'Last Name',
      slug: 'last-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  }
];

export const emailDomain = [];

@Component({
  components: {
    Container,
    BaseMultiSelect,
    AgreementSection,
    ValidationObserver,
    ValidationProvider,
    GroupAttributeForm,
    GroupNameAndTypeForm,
    EmailDomainForm,
    GroupCreationSubmitButtons,
    MembersUploadSection,
    ExistingMembersSelector,
    EditableMemberTable,
    ProtectedGroupSection,
    GroupLevelAttribute,
    UserAttributeTab
  }
})
export default class AdminCreateGroup extends Vue {
  public name = '';
  public type: UserAttributesStringInputOption | null = null;
  public protectedTypes: ProtectedGroupTypes[] = [];
  public userAttributes: MyjbiGroupUserAttributeSpec[] = [...defaultAttributes];
  // prettier-ignore
  public agreementFiles: Array<File | GetSignedUrlForUploadResponsePayload> = [];
  public membersFile: File | null = null;
  public membersData: MemberObject[] = [];
  public notify = false;
  public addMembersModal: any = null;
  public allowedEmailDomains: string[] = [];
  public createGroupLoading = false;
  public isMemberDataValid = true;
  public selectedProtectedGroupData: ProtectedGroupTypes | null = null;
  public protectGroup: boolean = false;
  currentTab: number = 0;
  activeGroupAttributes: GroupLevelAttributeValue[] = [];
  deleteGroupAttributes: GroupLevelAttributeValue[] = [];
  groupLevelAttributeFormHasError: boolean = false;
  isGroupLevelAttributeLoading: boolean = false;
  templateAttributes: GroupTemplateAttribute[] = [];
  saveAsTemplate: boolean = false;
  templateName: string = '';

  // prettier-ignore
  @Action('admin/createNewGroup')
  public createNewGroup!: (payload: CreateNewGroupRequestPayload) => Promise<CreateNewGroupResponsePayload>;

  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @Action('admin/getProtectedGroupTypes')
  getProtectedGroupTypes!: () => void;

  @Action('admin/createGroupLevelAttributesValues')
  createGroupLevelAttributeValues!: (
    payload: GroupLevelAttributeValuePayload
  ) => Promise<void>;

  @Action('admin/createGroupTemplate')
  public createGroupTemplate!: (payload: GroupTemplatePayload) => void;

  @State((state: RootState) => state.admin.verifyGroupTemplate)
  public verifyGroupTemplateResponse!: boolean;

  @State((state: RootState) => state.admin.protectedGroupTypes)
  public protectedGroupTypes!: GetProtectedGroupTypesResponsePayload;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[];

  @State((state: RootState) => state.rolesAndPermissions.groupExceptions)
  public groupExceptions!: ResourceExceptions;

  get isLocalOrDev() {
    return (
      process.env.VUE_APP_ENV === 'local' ||
      process.env.VUE_APP_ENV === 'development'
    );
  }

  get typeOptions(): UserAttributesStringInputOption[] {
    return Object.values(this.groupTypes).map((groupType: GroupType) => ({
      id: groupType.id,
      slug: groupType.name,
      name: groupType.name
    }));
  }

  get createNewGroupLoading() {
    return (this.$store.state as RootState).admin.apiState.createNewGroup
      .loading;
  }

  get groupId() {
    return this.$route.params.groupId;
  }

  get isLoadingActive(): boolean {
    return (
      !this.groupTypes ||
      this.createGroupLoading ||
      this.isGroupLevelAttributeLoading
    );
  }

  groupCreationFormHasError(
    isValidationObserverInvalid: boolean,
    isValidationObserverValidated: boolean
  ): boolean {
    return (
      !this.isMemberDataValid ||
      isValidationObserverInvalid ||
      !isValidationObserverValidated ||
      this.groupLevelAttributeFormHasError
    );
  }

  public handleAddExistingMember() {
    this.$buefy.modal.open({
      parent: this,
      component: AddMembersFromListModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        newGroup: true,
        userAttributes: this.userAttributes,
        existingMembers: this.membersData,
        allowedEmailDomains: this.allowedEmailDomains
      },
      events: {
        add: (payload: AddExistingMemberPayload) => {
          const { data } = payload;
          this.membersData = [...this.membersData, ...data];
        }
      }
    });
  }

  public handleRemoveMember(index: number) {
    this.membersData = this.membersData.splice(index, 1);
  }

  public handleAddMembersFromFile() {
    this.addMembersModal = this.$buefy.modal.open({
      parent: this,
      component: AddMembersFromFileModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        userAttributes: this.userAttributes,
        existingMembers: this.membersData,
        allowedEmailDomains: this.allowedEmailDomains
      },
      events: {
        add: (data: MemberObject[]) => {
          this.membersData = [...this.membersData, ...data];
          this.addMembersModal.close();
        }
      }
    });
  }

  public handleAddEmailDomains(domain: string) {
    this.allowedEmailDomains.push(domain);
  }

  public handleRemoveEmailDomain(tag: any) {
    const matchedIndex: number = this.allowedEmailDomains.indexOf(tag.tag);
    this.allowedEmailDomains.splice(matchedIndex, 1);
  }

  public async handleCreateGroup() {
    this.createGroupLoading = true;
    await this.createGroup();
  }

  /*
   * "activeGroupAttributes" is an array of group LEVEL attributes.
   * This method extracts the necessary properties from group level attributes
   * to construct group TEMPLATE attributes.
   *
   * Note:
   * Template attribute ordering should start with 1.
   */
  getGroupTemplateAttributes(): GroupTemplateAttribute[] {
    return this.activeGroupAttributes
      .filter((attribute: GroupLevelAttributeValue) => {
        return attribute.isDefault === false;
      })
      .map((selectedAttribute: GroupLevelAttributeValue, index: number) => {
        return {
          label: selectedAttribute.label,
          option:
            selectedAttribute.groupLevelAttributeType.type === 'list'
              ? this.clearListAttributeSelectedValue(selectedAttribute)
              : selectedAttribute.groupLevelAttributeType.option,
          status: GroupTemplateAttributeStatus.ACTIVE,
          groupLevelAttributeType: selectedAttribute.groupLevelAttributeType,
          order: index + 1,
          isRequired: selectedAttribute.isRequired
        };
      });
  }

  /**
   * When creating group template with list attribute,
   * selected value of lists should be cleared,
   * and only the selections should be preserved.
   */
  clearListAttributeSelectedValue(
    attribute: GroupLevelAttributeValue
  ): GroupLevelAttributeListType {
    return {
      options: (attribute.value as GroupLevelAttributeListType).options,
      selected: (attribute.value as GroupLevelAttributeListType)
        .isSingleSelection
        ? ''
        : [],
      isSingleSelection: (attribute.value as GroupLevelAttributeListType)
        .isSingleSelection
    };
  }

  handleUpdateTemplateDetails(saveAsTemplate: boolean, templateName: string) {
    this.saveAsTemplate = saveAsTemplate;
    this.templateName = templateName;
  }

  async createGroup() {
    const rootValidationObserver = this.$refs
      .rootValidationObserver as ValidationObserverInstance;

    if ((await rootValidationObserver.validate()) === false) {
      this.createGroupLoading = false;
      const message = !this.name?.trim()
        ? 'Name is required'
        : !this.userAttributes?.length
        ? 'User Attributes are required'
        : !this.membersData?.length
        ? 'Member data is required'
        : !this.type
        ? 'Group type is required'
        : 'Please fill in all the required fields';

      return DialogProgrammatic.alert({
        title: `Error`,
        message: `<p class="subtitle">${message}<p>`,
        type: 'is-primary'
      });
    }
    try {
      this.sanitizeMembersData();
      const payload: CreateNewGroupRequestPayload = {
        name: this.name,
        attributesSpecs: this.userAttributes,
        agreements: this.agreementFiles.map((file) => ({
          fileUri: (file as GetSignedUrlForUploadResponsePayload).storageUri,
          fileName: (file as GetSignedUrlForUploadResponsePayload).fileName
        })),
        userAttributesArr: userAttributesArrWithValues(
          this.membersData,
          this.userAttributes
        ),
        types: [this.type!.slug],
        notify: this.notify,
        emailDomains: this.allowedEmailDomains,
        protectedGroup: {
          isProtected: this.protectGroup,
          protectedGroupType: this.selectedProtectedGroupData
        }
      };
      const { id } = await this.createNewGroup(payload);

      if (this.activeGroupAttributes.length) {
        this.isGroupLevelAttributeLoading = true;
        await this.createGroupLevelAttributeValues({
          groupId: id,
          attributeValues: {
            selected: this.activeGroupAttributes
          }
        });
        this.isGroupLevelAttributeLoading = false;
      }

      if (
        !this.verifyGroupTemplateResponse &&
        this.saveAsTemplate &&
        this.templateName
      ) {
        const templateParams: GroupTemplatePayload = {
          templateName: this.templateName,
          groupId: id,
          attributes: {
            active: this.getGroupTemplateAttributes()
          }
        };
        this.createGroupTemplate(templateParams);
      }

      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `Group created`
      });

      this.$router.push({
        name: 'admin-view-group',
        params: { groupId: String(id) }
      });
    } catch (error: any) {
      this.createGroupLoading = false;
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  @Watch('userAttributes')
  onAttributeChange() {
    this.membersData = this.membersData.map((user: MemberObject) => {
      this.userAttributes.map((attribute: MyjbiGroupUserAttributeSpec) => {
        const { slug } = attribute.groupUserAttribute;
        user[slug] = validatedGroupUserAttributeValue(
          attribute,
          user,
          this.allowedEmailDomains
        );
      });
      return user;
    });
  }

  handleUpdateGroupLevelAttributeValues(
    activeValues: GroupLevelAttributeValue[],
    deleteValues: GroupLevelAttributeValue[],
    error: boolean
  ) {
    this.activeGroupAttributes = activeValues;
    this.deleteGroupAttributes = deleteValues;
    this.groupLevelAttributeFormHasError = error;
  }

  public sanitizeMembersData() {
    this.membersData = this.membersData.map((user: MemberObject) => {
      const arrayOfKeys = Object.keys(user);
      const attributeArray = this.userAttributes.map(
        (userAttr) => userAttr.groupUserAttribute.slug
      );

      arrayOfKeys
        .filter((userKeys) => !attributeArray.includes(userKeys))
        .map((keys) => {
          delete user[keys];
        });
      return user;
    });
  }

  public async mounted() {
    this.getProtectedGroupTypes();
    this.getGroupTypes();
    this.allowedEmailDomains = [];
  }
}
